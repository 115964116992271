<template>
  <v-text-field
    v-model="pwd"
    :rules="[(value) => !!value || this.$t('required')]"
    label="Password"
    :type="showPassword ? 'text' : 'password'"
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    autocomplete="off"
    @click:append="showPassword = !showPassword"
    @keyup="emitToParent"
    @focus="clearMsg"
  ></v-text-field>
</template>
<script>
  import store from '@/store';

  export default {
    data: () => ({
      showPassword: false,
      pwd: '',
    }),
    methods: {
      emitToParent() {
        this.$emit('passPwd', this.pwd);
      },
      clearMsg() {
        store.set('misc/alert', {});
      },
    },
  };
</script>
